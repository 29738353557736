// Here you can add other styles

.form-label {
    margin-top: 0rem !important;
}

.logoBox {
    width: 220px;
    height: 35px;
    box-shadow: 3px 7px 5px -2px rgba(97, 93, 97, 0.69);
}

.logoUploadBox {
    width: 120px;
    height: 120px;
    box-shadow: 3px 7px 5px -2px rgba(97, 93, 97, 0.69);
}

.logoUploadLabel {
    border-style: groove;
    border-radius: 5;
    width: 120px;
    text-align: center;
    box-shadow: 3px 7px 5px -2px rgba(97, 93, 97, 0.69);
}

.search_container
{
    position: relative;
    border-radius: 50px;
}

.search_button {
    color: #fff;
    background-color: #816ae9;
    display: inline-block;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 0px;
    right: -8px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
}
.search_button p{
    color: white;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-top: 14px;
    cursor:  pointer !important;
}

.search_text_mobile_button p {
    letter-spacing: 2px;
    cursor: pointer !important;
}

.loading_gif_acive{
    z-index: 9999;
    height: 100%;
    width: 100%;
    text-align: center;
    position: fixed;
    display: block;
    background-color: rgb(37 37 37 / 49%);
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.loading_gif_deactive{
    z-index: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.table-image-box > span.avatar-img{
	float: left;
	width: 100px;
	height: auto;
	padding:10px 17px 10px 20px;
}
.avatar-img img {
    width: 100%;
	border-radius: 50%;
  }

  .modal-body{
    padding-left: 50px;
    padding-right: 50px;
  }


  